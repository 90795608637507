import React from "react";
import { BlogPostJsonLd, GatsbySeo } from "gatsby-plugin-next-seo";


function AbruzzoNewss({pageContext, context}) {
    
console.log(pageContext, context)
const [data,setData]= React.useState([])
React.useEffect(()=> {
 async function name() {
    let response = await fetch(`https://trefle.io${pageContext.self}?token=xsLUcR2CZCjR7zA7kAq_LjpRqPhD0sGc7rXQbUyK6sc`);
    let json =  await response.json();
    console.log(json)
  }
 name()
},[])

  return (
    <>
      <GatsbySeo
        title={`${pageContext.common_name} | Casa Fraine`}
        description={pageContext.family}
        canonical={`https://www.casafraine.com/pianta/${pageContext.slug}`}
        openGraph={{
          url: `https://www.casafraine.com/pianta/${pageContext.slug}`,
          title: `${pageContext.common_name}`,
          description: `${pageContext.family}`,  
          site_name: "Casa Fraine",
          type: 'article',
          article: {
            publishedTime: '2023-06-21T23:04:13Z',
            modifiedTime: '2023-01-21T18:04:43Z',
            expirationTime: '2028-12-21T22:04:11Z',
            section: 'Plants',
            authors: [
              'https://www.vincenzo.codes/about'
                        ],
            tags: ['Pianta', 'Plants', 'Botanic','homemade food'],
          },
          images: [
            {
              url: `${pageContext.image_url}`,
              width: 850,
              height: 650,
              alt: `${pageContext.common_name}`,
            },
          ],
        }}
        twitter={{
          handle: "Vincenzo Marcovecchio",
          site: "www.casafraine.com",
          cardType: "summary_large_image",
        }}
      />
      <BlogPostJsonLd
        url={`https://www.casafraine.com/pianta/${pageContext.slug}/`}
        title={`${pageContext.common_name} | Casa Fraine`}
        authorName="Fronte popolare contadino"
        description={pageContext.family}
        images={[
          `${pageContext.image_url}`,
          `${pageContext.image_url}`,
          `${pageContext.image_url}`,
        ]}
        datePublished='2015-02-05T08:00:00+08:00'
        dateModified='2015-02-05T09:00:00+08:00'
      />
    <article className="article booking" >
    <img src={pageContext.image_url} alt={pageContext.common_name}/>
    <h1>{pageContext.common_name}</h1>
    <p>Family: {pageContext.family}</p>
    </article>
    </>
  );
}
export default AbruzzoNewss;